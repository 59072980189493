body {
  margin: 0;
  font-family:'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

td{
  border:1px solid black;
  border-left:none;
  border-right: none;
}

/* table{
  position:absolute;
  top:20px;
  left:50px
} */
.side-menu{
  /* float: left; */
  /* width: 20%; */
  height: 100vh;
  background: grey;
}
.snapshot-center-loaders{
  margin:auto
}

.snapshot-header{
  margin: auto;
    margin-bottom: 20px;
    background: #e6adad;
}
