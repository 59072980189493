.App {
  text-align: center;
  min-height:100vh;
  display: flex;
  flex-direction: column;
  position: relative
}

body{
 margin:0px;
 height: 0px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.header-login-text{
  margin-right: 40px;
    color: white;
    font-size: 1.25rem;
    /* border: 2px solid; */
    padding: 5px;
}


.loader-before-data{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40vh;
}

.main-loading-page-spinner{
  width: 5rem;
  height: 5rem;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  /* position: absolute; */
  margin: 50vh auto;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

#firebaseui-auth-container > div.registration-tips {
  font-size: 0.8rem;
  margin-bottom: 20px;
  color: #3a64a2;
}

.text-value {
  font-size: 8px;
  fill: #fff;
  opacity: 1;
}

.text-percent {
  font-size: 9px;
  fill: black;
  opacity: 0.9;
  font-size: 16px;
  font-weight: 600;
}

.text-label {
  font-size: 12px;
}

.zoom {
  transition: transform 0.2s;
  /* Animation */
}

div[id^="cell"] {
  margin-left: 50%;
  transform: translate(-100%);
  text-align: center;
}

.pie-chart-value-text {
  position: relative;
  left: -16px;
}

.bar-percent-wrapper-span {
  display: inline-block;
  font-size: 15px;
  margin-top: -9px;
  font-weight: 900;
  align-content: flex-start;
  justify-content: center;
  position: relative;
  top: -10px;
  border-bottom: 2px solid;
}

.zoom:hover {
  /* transform: scale(2.2); */
  opacity: 1;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.table-image-header {
  margin-left: 10px;
  width: 40px;
  height: 40px;
  background: #04ff0061;
  padding: 5px;
  border-radius: 50%;
  border: 1px solid;
}

.table-image-header-option-key {
  transform: rotateZ(331deg);
  color: white;
}

.image-snapshot-compliance-dmarc {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.chartjs-wrapper {
  height: 90px;
  width: 300px;
  margin: 25px auto 0;
  border: 1px solid #e6e6e6;
}

#chartjs-tooltip {
  opacity: 1;
  position: relative;
  color: #fff;
  background-color: #000;
  border-radius: 6px;
  transition: all 0.25s ease-in-out;
  pointer-events: none;
  padding: 4px;
}

#chartjs-tooltip:after {
  content: "";
  display: block;
  position: absolute;
  margin: auto;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 6px;
}

/* Top center */

#chartjs-tooltip.top.center:after {
  border-bottom-color: #000;
  top: -11px;
  left: 0;
  right: 0;
}

/* Top left */

#chartjs-tooltip.top.left:after {
  border-bottom-color: #000;
  left: 0px;
  top: -11px;
}

/* Top right */

#chartjs-tooltip.top.right:after {
  border-bottom-color: #000;
  right: 5px;
  top: -11px;
}

/* Bottom center */

#chartjs-tooltip.bottom.center:after {
  border-top-color: #000;
  bottom: -11px;
  right: 0;
  left: 0;
}

/* Bottom left */

#chartjs-tooltip.bottom.left:after {
  border-top-color: #000;
  bottom: -11px;
}

/* Bottom right */

#chartjs-tooltip.bottom.right:after {
  border-top-color: #000;
  bottom: -11px;
  right: 5px;
}

.sub-table {
  margin-top: 20px;
}

td.sub-table-success-col {
  background: #008000b5;
  font-weight: 900;
  color: white;
}

td.sub-table-failure-col {
  background: #ff0000d6;
  font-weight: 900;
  color: white;
}

.radio-geo-container {
  display: flex;
  justify-content: space-evenly;
}

.radio-geo-container input {
  margin: 3px;
}

.image-snapshot-compliance-dmarc button {
  min-width: 200px;
  margin: 20px;
}

.no-display {
  display: none;
}

/* react-bootstrap-sidebar */

.sidebar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: center;
  z-index: 100;
  padding: 10px 0 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
  text-align: left;
  justify-content: flex-start;
  min-height:100vh;
}
.sidebar-content {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 50px;
}

.sidebar-content.display-row{
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.menu-items-mobile{
  display: flex;
  z-index: 100;
  flex-wrap: wrap;
  display:none
}
.deactivated .nav-link-text {
  cursor: not-allowed;
  color: grey;
}
.compliance-buttons{
  display: flex;
  flex-direction: column;
}

/*switch-container td */

td.switch-container{
  padding-left:10px
}

/* slider */
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 6px;
  background-color: red;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider:before{
  background-color: green
}

/* input:checked + .slider {
  background-color: #2196F3;
} */

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(42px);
  -ms-transform: translateX(42px);
  transform: translateX(42px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 14px;
}

.slider.round:before {
  border-radius: 50%;
}

.dns_holder_modal_header{
  font-size: 1.25rem;
    background: #ff0000c9;
    margin: 12px;
    color: white;
    padding: 5px;

}
.dns_record_holder{
  color: white;
  margin: 12px;
  padding:5px
}
.dns_record_holder>div{
  background: #007bff;
  color: white;
  padding:5px;
  
}

div.dns_txt_record_fr{
  background: #1d3251;
  color: white;
  padding: 5px;
  word-break :all
}

.generated_fr_record_container{
  color: white;
  margin: 12px;
  padding:5px
}

.generated_fr_record_container_header{
  background: #007bff;
  color: white;
  padding:5px

}

.generated_fr_record{
  background: #1d3251;
  color: white;
  padding: 5px;

}

div.add-forensicreport-modal-body.modal-body > div.small.updated-conformation{
  color: #1d3251;
  font-weight: 800;
}


#dashboard-data div.authorised_domain_name_container table{
  color:white
}








@media print{

  html, body {
    width: 210mm;
    height: 297mm;
  }
  .no-print{
    display:none
  }

  .print-column{
    display:flex;
    flex-direction: column;
    margin:0 auto
  }
  .print-full-width{
    max-width:100%
  }

  .print-full-width .row{
    max-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-content: space-around;
    
  }

  .print-all-no-scroll{
    overflow:visible!important
  }

  .print-dashboard{
    margin:20px auto;
    text-align:center

  }

}





@media (max-width:1385px) {
  .sidebar{
    flex-direction: column;
    z-index: 100;
    padding: 10px 0 0;
    box-shadow: inset -1px 0 0 rgb(0 0 0 / 10%);
    text-align: left;
    justify-content: flex-start;
    min-height: 100vh;
    font-size: 12px;
  }
}

#sidebar-wrapper {
  min-height: 100vh !important;
  width: 100vw;
  margin-left: -1rem;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

.submit-dates {
  margin-top: 30px;
}

.seperator {
  margin-top: 20px;
  margin-bottom: 20px;
}

.filter-overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  background: #8080808a;
  color: pink;
  font-size: 20px;
}

.sidebar span {
  cursor: pointer;
}

.bg-light.sidebar svg {
  cursor: pointer;
}

.filter-form {
  position: fixed;
  top: 20%;
  left: 50%;
  width: 20%;
  background: #3a64a2;
  padding: 15px;
  border-radius: 15px;
  /* margin: 0 auto; */
  transform: translateX(-50%);
  color: white;
}


.download-link {
  
    margin: 20px;
    background: #0066ff;
    color: white;
    padding: 10px;
    border-radius: 5px;
}

.download-link-nodatafound{
    margin: 20px;
    background: indianred;
    color: white;
    padding: 10px;
    border-radius: 5px;
}

.download-link>a{
  color:white
}

.get-report-button{
  margin:10px
}

.filter-form-report {
  background: #3a64a2;
  padding: 15px;
  border-radius: 15px;
  width:80%;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 25px auto;
}
.filter-form-report .form-control{
  width:100%;
  margin-bottom: 25px;
}

.report-form{
  width:50%
}

.content-centre{
  position: absolute;
  top:50%
}



#page-content-wrapper {
  min-width: 0;
  width: 100%;
}

.close-icon {
  position: absolute;
  left: 100%;
  transform: translateX(-60%);
  font-size: 30px;
  font-weight: 900;
  /* display: none; */
  top: 30%;
  color: indianred;
}

.add-domain-header-modal-header{
  word-break:break-all
}

.admin-content-wrapper pre{
  outline:none
}

.admin-content-wrapper{
  display: flex;
  align-items: center;
  position: relative;
  /* height: 100vh; */
  flex-grow: 1;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.admin-content-wrapper-subscription{
  display: flex;
  align-items: center;
  position: relative;
  /* height: 100vh; */
  flex-grow: 1;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;


}


.subscription-details{
  width:50%;
  margin:25px;
  align-items: center;
  justify-content: flex-start;
}



.icon-before-in-menu {
  margin: 5px 10px 10px 8px;
}
.sub-menu-parent,.sub-menu-parent-hidden,.sub-menu-parent-displayed{
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  cursor:pointer
}
.sub-menu-parent-displayed{
  display:flex;
  flex-direction: column;
  align-items:center; 
}

.domain_check_wrapper {
  display: flex;
  flex-direction: column;
  align-content: space-around;
  flex-wrap: wrap;
  vertical-align: middle;
  justify-content: right;
}

.dmarc_reponse_list {
  text-align: left;
  list-style: none;
}

.domain-selector {
  margin: 0px auto;
  margin-bottom: 10px;
  color: green;
  margin-top:20px
}
.remove_domain_wrapper .text-header{

  background-color: rgb(226, 71, 71);
    color: white;
    height: 30px;
    text-align: center;
    font-weight: 500;
    line-height: 1.6;

}

.remove_domain_wrapper button{
  margin-top:20px
}


.dmarc_reponse_list button.btn.btn-primary {
  margin-top: 20px;
}

.unsucccesful-verification button {
  margin-left: 10px;
}

.overlay-wrapper {
  display: flex;
  flex-direction: column;
}

.overlay {
  height: 100%;
  position: fixed;
  background: grey;
  left: 0px;
  top: 0px;
  width: 100%;
  min-height: 100vh !important;
  z-index: 20;
  opacity: 0.5;
}

.overlay-item {
  top: 50%;
  position: absolute;
  color: white;
  z-index: 25;
  left: 50%;
  transform: translate(-50%, -50%);
}

.overlay-item span {
  margin: 2rem;
  color: white;
  margin-top: -34px;
  position: absolute;
  width: 110px;
}

.common-header-branding {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-content: center;
  background: #3a64a2;
  height: 70px;
  align-items: center;
}

.common-header-branding h1 {
  flex-grow: 11;
  font-size: 2rem;
  vertical-align: middle;
  align-content: center;
  display: inline-block;
  color: white;
  font-size: 30px;
}

.common-header-branding button {
  flex-grow: 1;
}

.sign-out-button {
  height: 40px;
  margin-top: 10px;
  margin-right: 10px;
  display: none;
}

svg.icon-before-in-menu {
  overflow: hidden;
  color: darkcyan;
}

.nav-link-text {
  color: #007bff;
}

#firebaseui-auth-container {
  width: 50%;
  margin: 10vh auto;
}

hr {
  border: solid 2px #ffc107;
  margin: 0px;
}

.currentdisplaydate {
  background: #3a64a2;
  color: white;
  width: auto;
  display: inline-block;
  font-weight: 700;
  padding: 10px;

}

#dashboard-data .currentdisplaydate~div button{
  margin-top:20px
}

#dashboard-data {
  margin-top: 20px;
  position: relative;
  /* height: 100vh; */
  padding-bottom: 215px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


label {
  margin: 0px 10px 10px 10px;
}

#dashboard-data .form-group .form-control {
  margin-right: 15px;
  margin-bottom: 15px;
}

.filter-label-easy button,
.filter-label-advanced button {
  margin-bottom: 10px;
}

.table td,
.table th {
  border: 0px;
}

table tr {
  border: 0px;
}

.inner-table-parent {
  border-bottom: 2px solid green;
  display: flex;
}

/* .inner-table-holder {
    margin: 0 auto
} */

td.auth_dkim {
  display: flex;
  flex-direction: column;
}

table {
  text-align: left;
}

.dmarc-fail-table-highlight {
  background: white;
  color: brown;
}

.dmarc-pass-table-highlight {
  background: white;
}

tr.inner-data-row {
  border-bottom: 2px solid #3a64a2;
  border-top: 2px solid #3a64a2;
}

tr.inner-data-row td {
  padding: 7px;
}

tr.data td {
  padding: 0.15rem;
  vertical-align: middle;
}

table.table-inner td,
table.table-inner th {
  padding: 0rem;
  padding-right: 4px;
  padding-bottom: 4px;
}

.table-inner tbody{
text-align:center
}

tr.inner-data-row.highlight-pass {
  background: white;
  animation: blinkingTextpass 8s ease-in 1s;
  color: #333333;
}

tr.inner-data-row.highlight-fail {
  background: pink;
  animation: blinkingTextfail 8s ease-in 1s;
  color: #333333;
}

table#organisation-report-table-top-level {
  width: 100%;
  margin: 0 auto;
}

.organisation-report-container{
  width:100%;
  height:400px;
  overflow:auto
}

tr.inner-data-row.display-off {
  display: none;
}

td.sub-table-success-col,
td.sub-table-failure-col {
  padding: 0.25rem;
}

.overlayModal {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 10;
  background: grey;
  color: white;
  text-align: center;
  opacity: 0.7;
}

.spinner-grow.text-primary {
  position: relative;
  top: 50%;
}

.invalid-domain-response {
  color: red;
  text-transform: uppercase;
}

#dashboard-data .authorised_domains_list {
  position: relative;
}

.authorised_domains_list .authorised_domain_header {
  background: #3a64a2;
  height: 26px;
  text-align: center;
  /* padding: 2rem; */
  color: white;
  font-size: 16px;
  margin: 0 auto;
  margin-top: 5px;
  vertical-align: middle;
}

#dashboard-data .authorised_domain_name_container {
  margin: 0 auto;
  text-align: left;
  vertical-align: middle;
  padding: 3rem;
  background: grey;
  color: white;
  font-size: 20px;
}

#dashboard-data > div > div > div > button {
  background-color: #3a64a2;
  width:50%
}

button.add_domain-first-button {
  margin: 20px auto;
}

/* d3.js component font-size */

text.text-percent {
  font-size: 8px;
  margin:4px
}

/* body -content */

.body-content {
  display: flex;
  align-content: space-around;
  /* align-items: center; */
  flex-direction: column;
 
}

/* email-form */
.message_acknowledgement {
  background: #3a64a2;
  color: white;
  font-size: 1.5rem;
  padding: 10px;
  margin-top: 20%;
}

.message_acknowledgement ~ div .btn {
  font-size: 1.5rem;
  margin-top: 10%;
}

/* add domain modal */
.add-modal-domain-header.modal-header {
  background: #1d3252;
  color: white;
}

button.close {
  color: white;
}

.authorised_domains_list {
  font-size: 1.5rem;
  font-weight: 700;
}

.dmarc_response_container li {
  margin-bottom: 10px;
}

.dmarc_record {
  background: #2ecc71;
  color: white;
  padding: 10px;
  margin-bottom: 30px;
}

.dmarc_data_value {
  text-align: center;
}

.netpillar-customer-ack {
  background: #1d3252;
  color: white;
  padding: 10px;
}

/* footer */

.span-or {
  display: block;
  position: absolute;
  left: 50%;
  top: -2px;
  margin-left: -25px;
  background-color: #fff;
  width: 50px;
  text-align: center;
}

.hr-or {
  background-color: #cdcdcd;
  height: 1px;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.footer-wrapper {
  margin-top: 10px;
  padding:0px 10px;
  background: #3a64a2;
}

.company-footer-wrapper {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height:150px
}

footer.nb-footer {
  background: #3a64a2;
  border-top: 2px solid #ffc107;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height:150px;
  position: absolute;
  z-index:103;
  bottom:-130px;
  height: 150px;  
}

footer.nb-footer .about {
  margin: 0 auto;
  margin-top: 5px;
  max-width: 1170px;
  text-align: center;
}

footer.nb-footer .about p {
  font-size: 13px;
  color: #999;
  margin-top: 30px;
}

footer.nb-footer .social-media {
  display: flex;
  flex-direction: column;
}

footer.nb-footer .about .social-media ul li a {
  display: inline-block;
  width: 25px;
  height: 25px;
  line-height: 18px;
  border-radius: 50%;
  font-size: 16px;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.3);
  /* background: #5f9ea0; */
  font-weight: 800;
  border: 1px solid white;
  color: white;
}

footer.nb-footer .about .social-media ul li a:hover {
  background: #b78c33;
  color: #fff;
  border-color: #b78c33;
}

footer.nb-footer .footer-info-single {
  margin-top: 5px;
}

footer.nb-footer .footer-info-single .title {
  color: white;
  text-transform: uppercase;
  font-size: 16px;
  /* border-left: 4px solid #b78c33; */
  padding-left: 5px;
}

.btn-verification {
  background: #5f9ea0;
  color: white;
  min-height: 40px;
  border-radius: 5px;
  outline: none;
  border: none;
  cursor: pointer;
}

footer.nb-footer .footer-info-single .title a {
  color: white;
}

footer.nb-footer .footer-info-single ul li a {
  display: block;
  color: #aaa;
  padding: 2px 0;
}

footer.nb-footer .footer-info-single ul li a:hover {
  color: #b78c33;
}

footer.nb-footer .footer-info-single p {
  font-size: 13px;
  line-height: 20px;
  color: #ff001833;
}

footer.nb-footer .copyright {
  margin-top: 15px;
}

footer.nb-footer .copyright p {
  margin: 0;
  padding: 0;
  text-align: center;
  color:white
}

.social-media-header {
  color: white;
  font-weight: 800;
  text-align: center;
  margin-bottom:10px
}

.social-media ul {
  list-style: none;
  display: flex;   
  justify-content: space-between;
  
}

.social-media ul li {
  width: 70px;
  height:70px;
  background: white;
  border-radius: 50%;
  margin-right: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.details-netpillar{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.details-netpillar ol{
  display: flex;
  flex-direction: column;
  height: 150px;
  justify-content: space-evenly;
}


.details-netpillar li {
  list-style: none;
}
.details-netpillar li a {
  color: white;
}

.list-inline {
  padding-left: 0;
  list-style: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.copyright-container,
.copyright-container p {
  margin: 0 auto;
  color:white
}

.footer-text-content-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
}

.list-inline i {
  font-size: 0.7rem;
}

/* json individual */

pre {
  outline: 1px solid #ccc;
  padding: 5px;
  margin: 5px;
}

.string {
  color: green;
}

.number {
  color: darkorange;
}

.boolean {
  color: blue;
}

.null {
  color: magenta;
}

.key {
  color: red;
}

/* footer */

/* email not verified */

.unverified-email {
  width: 100%;
  color: white;
  font-size: 1.2rem;
  margin-bottom: 20px;
  border-radius: 5px;
  /* border:1px solid grey; */
  font-size: 16px;
  margin-top: 20px;
  background: #3a64a2;
}

.unverified-email p {
  padding: 20px;
  margin: 0;
}

.form-inline.timerblock {
  flex-direction: column;
}

#unverified-email > div.form-inline.timerblock > div > pre {
  color: red;
}

.form-inline button {
  margin-top: 20px;
  margin-right: 20px;
  margin-bottom: 5px;
}

.form-inline button:disabled {
  background: grey;
  cursor: not-allowed;
}

#unverified-email .login {
  background: #5f9ea0;
  color: white;
  min-height: 40px;
  border-radius: 5px;
}

#unverified-email > div.form-inline.timerblock .verify-instructions {
  background: #3a64a2;
  color: white;
  padding: 20px;
  margin-top: 60px;
}

.home-page-go-home {
  margin-top: 20px;
}

/* hamburger icon */
.container-hamburger {
  display:none
}

.bar1, .bar2, .bar3 {
  width: 35px;
  height: 5px;
  background-color: #333;
  margin: 6px 0;
  transition: 0.4s;
}

.change.bar1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  transform: rotate(-45deg) translate(-9px, 6px);
}

.change.bar2 {opacity: 0;}

.change.bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -8px);
}

.big-close{
  font-size:1.5rem;
  color:orange

}
/* media queries */

@media (max-width: 768px) {
  .sign-out-button {
    height: 40px;
    width: 30px;
    margin-top: 10px;
    margin-right: 10px;
    display: inline-block;
  }
  #dashboard-data {
    margin-left: 18%;
  }
  .domain-selector select {
    margin-left: 0%;
    width: 100%;
  }
  .domain-selector h3 {
    font-size: 1rem;
  }
  .sidebar {
    font-size: 12px;
  }
  .sidebar span {
    cursor: pointer;
  }
}

@media (max-width: 1024px) {
  .sidebar {
    font-size: 10px;
  }
}

@media (max-width: 767px) {
  .sidebar {
    font-size: 0.6rem;
  }
  .nav-link-text {
    font-size: 8px;
  }
  footer.nb-footer .about .social-media ul li a {
    display: inline-block;
    width: 18px;
    height: 18px;
    line-height: 16px;
    border-radius: 50%;
    font-size: 11px;
    color: white;
    border: 1px solid rgba(255, 255, 255, 0.3);
    background: #5f9ea0;
  }
  footer.nb-footer .footer-info-single {
    margin-top: 0px;
  }
  .footer-text-content-wrapper {
    display: flex;
    align-content: space-around;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
  }
  footer.nb-footer .footer-info-single .title {
    color: #aaa;
    text-transform: uppercase;
    font-size: 8px;
    /* border-left: 4px solid #b78c33; */
    padding-left: 5px;
  }
  .footer-text-content-wrapper {
    display: flex;
    align-content: space-around;
    justify-content: center;
    width: 100%;
    flex-wrap: nowrap;
  }
  #unverified-email {
    width: 40%;
    color: red;
    font-size: 1rem;
  }
}



@media (max-width: 576px) {
  /* .nav-link-text {
    display: none;
  } */

  .container-hamburger {
    display: inline-block;
    cursor: pointer;
    
  }
  .close-icon {
    display: none;
  }
  .sidebar {
    display:none
}

.menu-items-mobile{
  display:flex
}
  
  
  .admin-content-wrapper{
    margin-top:70px
  }
    
  #dashboard-data {
    position: relative;
    top: 75px;
  }
  /* .common-header-branding h1 {
    font-size: 0.6rem;
  } */
  .filter-form {
    position: ABSOLUTE;
    top: 20px;
    left: 20%;
    background: GREY;
  }
  .domain-selector {
    margin: 10px auto;
  }
  .filter-label-advanced button {
    margin-top: 10px;
  }
  .filter-form {
    position: ABOLUTE;
    top: 20px;
    left: 10%;
    background: GREY;
    width: 80%;
  }
  footer.nb-footer {
    position: relative;
  }
}

#unverified-email {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.feedback-form-center {
  margin: 0 auto;
}

/* faq
 */

.faq-content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
  background: #f39c12;
  padding: 20px;
  font-weight: 800;
}

.highlight-info {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: space-around;
  justify-content: space-between;
  align-items: flex-start;
  background: black;
  font-size: 2rem;
  padding: 50px;
  color: white;
  margin-top: -20px;
}

.dmarc-card {
  min-height: 40vh;
  background: #02ac61;
  color: white;
}

.dmarc-card h6 {
  text-align: left;
}

.card-text-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
}

.dmarc-card h4 {
  font-size: 2.5rem;
}

.dmarc-card h6 {
  font-size: 2rem;
}
.faq-content-single-block span {
  margin: 10px;
  color: #3a64a2;
  line-height: 3;
}
.faq-content-single-block h4 {
  color: white;
}

.join-now {
  margin-left: 20px;
  color: white;
}

.join-now.btn.btn-primary .nav-link-text {
  color: white;
}
.dmarc-qa-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: space-around;
  text-align: left;
  padding: 10px;
}

.dmarc-q {
  color: #2ecc71;
  font-weight: 800;
}

.dmarc-ans {
  margin-left: 0px;
  line-height: 1.75;
  color: #3a64a2;
  font-weight: 800;
  margin-bottom: 30px;
  margin-top: 20px;
}

.product-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}
.product {
  width: 50%;
  height: 100px;
  border-radius: 5px;
  background: rgb(84, 105, 212);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-right: 5px;
}
.product>.title {
  font-size:16px;
  margin-top:20px
}


.product:hover {
  cursor: pointer;
  opacity: 0.7;
}
/* .product-stripe {
  background: rgb(221, 41, 71);
} */
.product-paypal {
  background: rgb(84, 105, 212);
}
.title {
  color: white;
}
.price {
  color: white;
  font-size: 16px;
  font-weight: 500;
}
.wizard {
  display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin-bottom: 20px;
    margin-top: 20px;
}

.wizard>div{
  width:100%
}

/* .btn-group {
 
} */
.btn-checkout {
  width: 100px;
  height: 40px;
  background: green;
  border: none;
  border-radius: 5px;
}

.btn-next {
  width: 100px;
  height: 40px;
  background: lightskyblue;
  border: none;
  border-radius: 5px;
}

.btn-next:hover {
  opacity: 0.7;
}

.btn-previous {
  width: 100px;
  height: 40px;
  background: lightskyblue;
  border: none;
  border-radius: 5px;
  margin-right: 5px;
}

.btn-previous:hover {
  opacity: 0.7;
}

.wizard-wrapper {
  margin-top: 20px;
  width: 100%;
  box-shadow: 0px 0px 2px 2px rgb(179 168 168);
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.wizard-wrapper > div.alert.alert-danger{
  margin:20px 10px 10px 10px;
  width:100%
}

.wizard-buttons{
  margin-bottom:20px
}

.btn-general-stripe {
  width: 50%;
  height: 50px;
  background: pink;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  margin: 10px;
  position: relative;
  left:50%;
  transform:translate(-50%)
}

.text-center.info-paypal-coming-soon {
  font-size: 0.75rem;
  color: #256a6a;
}

.btn-general-paypal {
  width: 40%;
  height: 50px;
  background: skyblue;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  margin: 10px;
  position: relative;
}

.img-checked {
  height: 30px;
  width: 30px;
  left: 15px;
  top: 10px;
}

.stripe-container {
  display: flex;
}
.paypal-container {
  display: flex;
}


.payment-selection-main{
    display: block;
    font-size: 25px;
    font-family: sans-serif;
    font-weight: 700;
    color: #444;
    line-height: 1.3;
    padding: .6em 1.4em .5em .8em;
    width: 100%;
    max-width: 50%; /* useful when width is set to anything other than 100% */
    box-sizing: border-box;
    margin: 0;
    border: 1px solid #aaa;
    box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
    border-radius: .5em;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    /* note: bg image below uses 2 urls. The first is an svg data uri for the arrow icon, and the second is the gradient. 
      for the icon, if you want to change the color, be sure to use `%23` instead of `#`, since it's a url. You can also swap in a different svg icon or an external image reference
      
    */
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
      linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
    background-repeat: no-repeat, repeat;
    /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
    background-position: right .7em top 50%, 0 0;
    /* icon size, then gradient */
    background-size: .65em auto, 100%;
  }
  /* Hide arrow icon in IE browsers */
  .select-css::-ms-expand {
    display: none;
  }
  /* Hover style */
  .select-css:hover {
    border-color: #888;
  }
  /* Focus style */
  .select-css:focus {
    border-color: #aaa;
    /* It'd be nice to use -webkit-focus-ring-color here but it doesn't work on box-shadow */
    box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
    box-shadow: 0 0 0 3px -moz-mac-focusring;
    color: #222; 
    outline: none;
  }
  
  /* Set options to normal weight */
  .select-css option {
    font-weight:normal;
  }
  
  /* Support for rtl text, explicit support for Arabic and Hebrew */
  *[dir="rtl"] .select-css, :root:lang(ar) .select-css, :root:lang(iw) .select-css {
    background-position: left .7em top 50%, 0 0;
    padding: .6em .8em .5em 1.4em;
  }
  
  /* Disabled styles */
  .select-css:disabled, .select-css[aria-disabled=true] {
    color: graytext;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22graytext%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
      linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
  }
  
  .select-css:disabled:hover, .select-css[aria-disabled=true] {
    border-color: #aaa;
  }

  /* reactStepperPayment */

  .stepper-wrapper {
    width:50%;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .stepper-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
  }
  
    
  
  .stepper-item::before {
    position: absolute;
    content: "";
    border-bottom: 2px solid #ccc;
    width: 100%;
    top: 20px;
    left: -50%;
    z-index: 2;
  }
  
  .stepper-item::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #ccc;
    width: 100%;
    top: 20px;
    left: 50%;
    z-index: 2;
  }
  
  .stepper-item .step-counter {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #ccc;
    margin-bottom: 6px;
  }
  
  .stepper-item.active {
    font-weight: bold;
  }
  
  .stepper-item.completed .step-counter {
    background-color: #4bb543;
  }
  
  .stepper-item.completed::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #4bb543;
    width: 100%;
    top: 20px;
    left: 50%;
    z-index: 3;
  }
  
  .stepper-item:first-child::before {
    content: none;
  }
  .stepper-item:last-child::after {
    content: none;
  }


.adminserviceswrapper{
    display: flex;
    flex-direction: row;
    align-content: space-around;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap
}
  .metadatauploadwrapper{
  
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-content: center;
      align-items: center;
      border: 2px solid;
      margin: 50px;
      border-radius: 11px;
      width: 100%;
      padding:20px
  
  }

  .upload-response{
    width: 50%;
    margin: 20px;
    height: 200px;
    overflow: auto;
    word-break: break-all;
}
  

  .upload-headers-text{
    background-color: green;
    color:white
 }

 .upload-response-text.saml-details {
  text-align: left;
  background: #3a64a2;
  color: white;
  padding: 10px;
  font-size: 14px;
}

.upload-response-text.saml-details span {
  text-align: center;
  background-color: black;
  color:white;
  font-size:1.2rem
 
}

.upload-response-text.saml-details .save-instructions {
  text-align: center;
  margin-top: 30px;
  background: white;
  color: black;
}
 

  .userdisplaywrapper{

    display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-content: center;
      align-items: center;
      border: 2px solid;
      margin: 50px;
      border-radius: 11px;
      width: 100%;
      padding:10px
  

  }

  .userdisplaywrapper table tr {
    border:1px solid
  }

  .userfields div{
    margin:10px
  }
.userfields label{
  margin:0px
}
 .userfields small{
   color:#3f51b5
 }

 .userfields svg{
   margin-right:5px
 }
 .individual-domain{
  color: white;
    margin: 5px;
    background: #4462cf;
    padding: 10px;

 }

 .remove_domain_wrapper{
  width: 100%;
  margin: 50px auto;

 }

 .remove_domain_response{
    background: darkolivegreen;
    height: 40px;
    color: white;
    text-align: center;
    line-height: 2.5;
    margin-top: 20px;

 }

 .saml-settings-page-header{
  background: #3a64a2;
  font-size: 1.5rem;
  color: white;
  margin-top: 50px;
  padding: 5px;
  border-radius: 8px;
 }
div.spinner-center-page{
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.adminpanel-home-containers{
    display: flex;
    align-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(0px, -50%)
}

.unsucccesful-verification{
  background: #cd5c5c;
    color: white;
    margin-top: 20px;
    height: 50px;
    line-height: 2.5;
    font-size: 20px;
    border-radius: 10px;
}

ul.dmarc_reponse_list>li>span{
  background: #1d3251;
  color: white;
  text-align: center;
  display: block;
  padding: 5px;
  margin-bottom: 10px;
}

 

 
       

